import { render, staticRenderFns } from "./buildOvertime.vue?vue&type=template&id=2466ee60&scoped=true&"
import script from "./buildOvertime.vue?vue&type=script&lang=js&"
export * from "./buildOvertime.vue?vue&type=script&lang=js&"
import style0 from "./buildOvertime.vue?vue&type=style&index=0&id=2466ee60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2466ee60",
  null
  
)

export default component.exports